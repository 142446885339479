import Alerts from './alerts'

var favourites = (function($) {
  'use strict';
	function init() {
		markFavourites();
		bindClickEvents();
	}

	function markFavourites(){
		let url = '/favourites/list'
		$.ajax({
		   type: "GET",
		   url: url,
		   dataType: "json"
		 }).done(function(json){
		    let productIds = json.ids;
		    updateFavouritesCounter(json.ids.length);

		    //console.log(productIds)	

		    productIds.forEach(id => markProductAsFavourite(id));


		 }).fail(function(xhr, status, error) { 
		 	console.error(error);     
		 }); 
	}

	function bindClickEvents(){

		var favouteProductsContainer = $('#favourites-container');
		favouteProductsContainer.on('product-removed', function(e, id){
			let productContainer = $(".js-product-index-container[data-product-id='" + id +"']");
			productContainer.remove();

		//	let productsCount = $(".js-shopping-cart-item-container").length || 0;
			let productsCount = $(".js-product-index-container").length || 0;
			if(productsCount < 1){
				$('.js-no-favourite-products-message').removeClass('display-none');
			}
		});

		$('.js-add-to-favourites').click(function(){
			let productId = $(this).data('product-id');
			let url = $(this).data('url');

			$.ajax({
			   type: "POST",
			   url: url,
			   data: {
			   	product_id: productId
			   },
			   dataType: "json"
			 }).done(function(json){
			    console.log(json.message)

			    // if(json.message != undefined){
			    // 	Alerts.showAlert(json.message, 'success', true);
			    // }

			    updateFavouritesCounter(json.counter);
			    markProductAsFavourite(productId);

			 }).fail(function(xhr, status, error) { 
			 	let errors = ['Провалена заявка'];

			 	try {
				  errors = $.parseJSON(xhr.responseText).errors; 
				} catch (error) {
				  console.error("Error while parsing errors response")
				  console.error(error);
				}

				Alerts.clearAlerts();
				errors.forEach(e => Alerts.showAlert(e, 'fail'));
			 }); 
		});

		$('.js-remove-from-favourites').click(function(){
			let productId = $(this).data('product-id');
			let url = $(this).data('url');

			$.ajax({
			   type: "DELETE",
			   url: url,
			   data: {
			   	product_id: productId
			   },
			   dataType: "json"
			 }).done(function(json){
			    // if(json.message != undefined){
			    // 	Alerts.showAlert(json.message, 'success', true);
			    // }
			    updateFavouritesCounter(json.counter);
			    removeProductFromFavourtes(productId);

			    // notify the favourite products container that a product was removed
				favouteProductsContainer.trigger('product-removed', [productId]);

			 }).fail(function(xhr, status, error) { 
			 	let errors = ['Провалена заявка'];

			 	try {
				  errors = $.parseJSON(xhr.responseText).errors; 
				} catch (error) {
				  console.error("Error while parsing errors response")
				  console.error(error);
				}

				Alerts.clearAlerts();
				errors.forEach(e => Alerts.showAlert(e, 'fail'));
			 }); 
		});
	}

	function markProductAsFavourite(productId){
		$('.js-add-to-favourites[data-product-id="' + productId + '"]').addClass('display-none');
		$('.js-remove-from-favourites[data-product-id="' + productId + '"]').removeClass('display-none');
	}

	function removeProductFromFavourtes(productId){
		$('.js-add-to-favourites[data-product-id="' + productId + '"]').removeClass('display-none');
		$('.js-remove-from-favourites[data-product-id="' + productId + '"]').addClass('display-none');
	}

	function updateFavouritesCounter(counter){
		let displayText = '';

		if(counter > 9){
			displayText = '9+';
		}else if(counter > 0){
			displayText = counter;
		}
		
		$('.js-favourites-counter').html(displayText);
	}

  return {
    init: init
  };

})(jQuery);

export default favourites;