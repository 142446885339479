document.addEventListener('turbolinks:load', function (event) {

    $('.side_menu').on( "click", function(){
        $('.sidenav').width('320');
        $('.page-wrapper').addClass('blur');
        $('body').toggleClass('remove-scroll');
    })

    $('.closebtn').on( "click", function(){
        $('.sidenav').width('0');
        $('.page-wrapper').removeClass('blur');
        $('body').toggleClass('remove-scroll');
    })

    $('.mobile-menu-link').each(function() {
        $(this).on("click", function() {
            $('.closebtn').click();
        })
    })
});