document.addEventListener('turbolinks:load', function (event) {

    //dynamically adjust main class minimum height
    $(window).on('resize',function() {
        let topMessageHeight = $('.top-message').outerHeight();
        let headerHeight = $('.header').outerHeight();
        let footerHeight = $('.footer').outerHeight();

        let mainContainerHeight = ($(window).outerHeight()- (topMessageHeight + headerHeight + footerHeight));
        
        $('.main').css('min-height', mainContainerHeight);
    });
    
    $(window).trigger('resize');

    // disable zoom on input click

    const addMaximumScaleToMetaViewport = () => {
        const el = document.querySelector('meta[name=viewport]');
      
        if (el !== null) {
          let content = el.getAttribute('content');
          let re = /maximum\-scale=[0-9\.]+/g;
      
          if (re.test(content)) {
              content = content.replace(re, 'maximum-scale=1.0');
          } else {
              content = [content, 'maximum-scale=1.0'].join(', ')
          }
      
          el.setAttribute('content', content);
        }
      };
      
      const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;
      
      const checkIsIOS = () =>
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      
      if (checkIsIOS()) {
        disableIosTextFieldZoom();
      }

});