var productFilters = (function($) {
  'use strict';

	function init() {
		bindProducerFilter();
		showCategoriesFilter();
		showBrandsFilter();
		openSubcategory();
		bindSortByFilter();
		bindCategoryFilter();
	}

	function bindProducerFilter(){
		$('.js-producer-filter').change(function(){
			$('.js-product-filter-form').submit();
		})
	}

	function showCategoriesFilter(){
		$('.categories-filter-btn').on('click', function(){
			$('.categories-container').toggleClass('display-block');
			$('.categories-filter-btn .fa-bars').toggleClass('display-none');
			$('.categories-filter-btn .fa-times').toggleClass('display-block');
		}); 
	}

	function showBrandsFilter(){
		$('.brands-filter-btn').on('click', function(){
			$('.boxes').toggleClass('display-block');
			$('.brands-filter-btn .fa-bars').toggleClass('display-none');
			$('.brands-filter-btn .fa-times').toggleClass('display-block');
		}); 
	}

	function openSubcategory(){
		let arrowOpenSubcategory = $('.js-open-subcategory');
		let subcategoryMenu = $('.each-main-category').next('ul');
		arrowOpenSubcategory.on('click', function(event){
			if(!subcategoryMenu.hasClass('display-block')) {
				$(this).parent().next('ul').slideToggle(500).toggleClass('display-block');
			}
			else {
				$(this).parent().next('ul').slideToggle(500).toggleClass('display-block');
			}
		}); 
	}

	function bindSortByFilter(){
		$('.js-products-sort-by').change(function(e){
			var sortFilterValue = this.value;
			var secondInputValue = sortFilterValue;
			$('.js-form-input').val(secondInputValue);
			$('.js-product-filter-form').submit();
		})
	}

	function bindCategoryFilter(){
		const filterCategory = $('#filter_category_id');
		$('.js-category-filter').on('click', function(e){
			e.preventDefault();
			let id = $(this).data('id');
			filterCategory.val(id);
			$('.js-product-filter-form').submit();
		})
	}

  return {
    init: init
  };

})(jQuery);

export default productFilters;