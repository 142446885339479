// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
import Alerts from './web/alerts'
import Favourites from './web/favourites'
import ShoppingCart from './web/shopping_cart'
import './web/search_product'
import './web/mobile_menu'
import './web/product_gallery'
import './web/modal_popup'
import './web/validate'
import './web/script'

import 'cookieconsent/build/cookieconsent.min.js'
import Cookies from './web/cookies'


import 'owl.carousel';
import Slider from './web/slider'
import ProductFilters from './web/product_filters'
// import './web/favourites'

Rails.start()
Turbolinks.start()
// ActiveStorage.start()


document.addEventListener('turbolinks:load', function (event) {
  Cookies.init();
  Alerts.init();
  Favourites.init();
  ShoppingCart.init();
  Slider.init();
  ProductFilters.init();
  // Alerts.showAlert('test', 'success');
  // Alerts.showAlert('test', 'error');
  // Alerts.showAlert('test', 'notice');
});


document.addEventListener('turbolinks:before-cache', function (event) {
	Slider.destroy();
});