var slider = (function($) {
  'use strict';

  function init() {
    $('.owl-one').owlCarousel({
        items:1,
        // nav: true,
        loop:true,
        margin:10,
        autoplay:true,
        autoplayTimeout:3500,
        autoplayHoverPause:true
    });
    $('.play').on('click',function(){
        owl.trigger('play.owl.autoplay',[1000])
    })
    $('.stop').on('click',function(){
        owl.trigger('stop.owl.autoplay')
    });

    $('.owl-two').owlCarousel({
        items:4,
        // nav: true,
        loop:true,
        margin:10,
        autoplay:true,
        autoplayTimeout:3500,
        autoplayHoverPause:true
    });
    $('.play').on('click',function(){
        owl.trigger('play.owl.autoplay',[1000])
    })
    $('.stop').on('click',function(){
        owl.trigger('stop.owl.autoplay')
    });

   
            // $('.owl-one').owlCarousel({
            //     items:1,
            //     // nav: true,
            //     loop:true,
            //     margin:10,
            //     autoplay:true,
            //     autoplayTimeout:3500,
            //     autoplayHoverPause:true
            // });
            // $('.play').on('click',function(){
            //     owl.trigger('play.owl.autoplay',[1000])
            // })
            // $('.stop').on('click',function(){
            //     owl.trigger('stop.owl.autoplay')
            // })

            // $('.owl-two').owlCarousel({
            //     items:4,
            //     // nav: true,
            //     loop:true,
            //     margin:10,
            //     autoplay:true,
            //     autoplayTimeout:3500,
            //     autoplayHoverPause:true
            // });
            // $('.play').on('click',function(){
            //     owl.trigger('play.owl.autoplay',[1000])
            // })
            // $('.stop').on('click',function(){
            //     owl.trigger('stop.owl.autoplay')
            // })
  }

  function destroy(){
     $(".owl-one").owlCarousel('destroy');
     $(".owl-two").owlCarousel('destroy');
  }


  return {
    init: init,
    destroy: destroy
  };

})(jQuery);

export default slider;